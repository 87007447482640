<!-- 忘记密码 -->
<template>
  <div class="forgetPassword">
    <header>
      <div>
        <p style="font-size:18px">黄埔人才网 | 企业端</p>
      </div>
      <div>
        <Button type="error" @click="$router.back()">登录</Button>
      </div>

    </header>

    <div class="text">
      <p>我的位置：忘记密码</p>

    </div>
    <section>
      <div class="content">
        <Input v-model="mobile" style="width:500px">
        <span slot="prepend">手机号</span>
        </Input>

        <Input v-model="authcode" style="margin:20px 0;width:500px">
        <span slot="prepend">验证码</span>
        <Button slot="append" type="text" @click="get_authcode" :loading="loading" :disabled='disabled'>{{text}}</Button>
        </Input>

        <Input v-model="newPassword" style="margin-bottom:20px;width:500px" type="password" password>
        <span slot="prepend">新密码</span>
        </Input>

        <Input v-model="password_tow" type="password" password style="width:500px">
        <span slot="prepend">确认密码</span>
        </Input>

        <Button type="warning" style="margin-top:20px;" @click="ok" :loading="loading_login">提交</Button>

      </div>
    </section>
    <footer>

    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading_login: false,
      mobile: "",
      authcode: "",
      loading: false,
      disabled: false,
      newPassword: '',
      password_tow: '',
      text: '获取验证码',
    }
  },
  methods: {
    //获取验证码
    get_authcode() {
      //验证手机号格式
      if (/^1[3-9][0-9]{9}$/.test(this.mobile)) {

        //验证手机号是否已注册
        this.loading = true
        this.app('enterpriseOperators', 'checkRegisterMobile', { mobile: this.mobile }).then(res => {
          if (res.data.mobileRegister) {
            //已被注册可以发送验证码
            this.app('enterpriseOperators', 'sendRegisterCode', { mobile: this.mobile }).then(res => {
              this.loading = false
              this.disabled = true
              this.text = '60'
              let clock = setInterval(() => {
                if (this.text) {
                  this.text--
                } else {
                  this.text = '获取验证码'
                  clearInterval(clock)
                  this.disabled = false
                }
              }, 1000);
            })
          } else {
            this.loading = false
            this.$Modal.warning({
              title: "您还未注册，请先注册再登录！",
              onOk: () => {
                this.$router.push('Register')
              },
              closable: true
            })
          }
        })
      } else {
        this.$Modal.warning({
          title: "手机号码格式不正确"
        })
      }
    },
    ok() {
      //验证手机号及验证码格式
      if (/^1[3-9][0-9]{9}$/.test(this.mobile) && /^[0-9]{6}$/.test(this.authcode)) {
        //验证两个密码一致及是否为空
        if (this.newPassword == this.password_tow && this.newPassword && this.password_tow) {
          //验证密码格式
          if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/.test(this.newPassword)) {
            this.loading_login = true
            //验证验证码真伪
            // this.app('enterpriseOperators', 'checkCode', {
            //   mobile: this.mobile,
            //   code: this.authcode
            // }).then(res => {

            //   if (res.code == 200) {

                this.app('enterpriseOperators', 'restEmployeePassword',
                  {
                    mobile: this.mobile,
                    newPassword: this.newPassword,
                    code: this.authcode
                  }).then(re => {
                    if (re.code == 200) {
                      this.$Modal.warning({
                        title: "设置成功，返回登录页",
                        onOk: () => {
                          this.$router.push('Home')
                        },
                        closable: true
                      })
                    } else {
                      this.$Modal.warning({
                        title: re.message
                      })
                    }
                    console.log(re);
                    this.loading_login = false
                  })
            //   } else {
            //     this.$Modal.warning({
            //       title: res.message
            //     })
            //     this.loading_login = false
            //   }
            // })
          } else {
            this.$Modal.warning({
              title: "密码格式不正确,请输入至少六位由数字或字母组成的密码"
            })
          }


        } else {
          this.$Modal.warning({
            title: "密码不一致或为空"
          })
        }

      } else {
        this.$Modal.warning({
          title: "手机号码或验证码格式不正确"
        })
      }
    }
  },
  created() {

  }
}
</script>

<style lang="less" scoped>
@import "./forgetPassword.less";
</style>
